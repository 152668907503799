import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import "@fontsource/ubuntu/500.css";
import "./css/index.css";
import { useRouteError, Link } from "react-router-dom";
import Card from "@mui/material/Card";

export default function ErrorPage() {
  const theme = useTheme();
  const error = useRouteError();
  console.error(error);

  return (
    <ThemeProvider theme={theme}>
      <Container
        disableGutters
        maxWidth="100%"
        m={0}
        p={0}
        sx={{ height: "100vh", bgcolor: "primary.lighter" }}
      >
        <AppBar position="static">
          <Toolbar>
            <Typography theme={theme} component="div" sx={{ flexGrow: 1 }}>
              <Button
                component={Link}
                to="/"
                size="large"
                variant="contained"
                color="primary"
                sx={{
                  m: 1,
                  textTransform: "none",
                  ":hover": { color: "black.main", bgcolor: "secondary.main" },
                }}
              >
                <Typography variant="h3">pat kupcha</Typography>
              </Button>
            </Typography>

            <Button
              variant="contained"
              component={Link}
              size="large"
              to="/projects"
              sx={{ m: 1, textTransform: "none" }}
            >
              projects
            </Button>

            <Button
              variant="contained"
              component={Link}
              to="/contact"
              size="large"
              sx={{
                m: 1,
                textTransform: "none",
                ":hover": { color: "white.main", bgcolor: "primary.light" },
              }}
            >
              contact
            </Button>
          </Toolbar>
        </AppBar>
        <Card raised="true" sx={{ m: 10, p: 5, textAlign: "center" }}>
          <Typography variant="h3" sx={{ m: 2, p: 1 }}>
            This page doesn't exist.
          </Typography>
          <Typography variant="h5" sx={{ m: 2, p: 1 }}>
            You'll have to try again, friend.
          </Typography>
        </Card>
      </Container>
    </ThemeProvider>
  );
}
