import React from "react";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import SouthIcon from "@mui/icons-material/South";
import TerminalTwoToneIcon from "@mui/icons-material/TerminalTwoTone";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import SchoolIcon from "@mui/icons-material/School";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

// let theme = createTheme({
//   palette: {
//     primary: {
//       main: "#27996B",
//     },
//     secondary: {
//       main: "#FF3634",
//     },
//     white: {
//       main: "#ffffff",
//     },
//     black: {
//       main: "#000000",
//     },
//     bg: {
//       main: "#f5f5f5",
//     },
//   },
//   textTransform: "none",
//   typography: {
//     fontFamily: "OCFormatCollage",
//   },
// });
// theme = responsiveFontSizes(theme);

const Bio = () => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" disableGutters sx={{ justifyContent: "center" }}>
        <Stack display="inline" alignItems="center" marginBottom={2}>
          {/* I AM PAPER */}
          <Paper
            elevation={5}
            sx={{
              p: 0.5,
              mx: 0.5,
              marginTop: 2.5,
              marginBottom: 2.5,
              bgcolor: theme.palette.bg.main,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h4"
              m={0.5}
              sx={{ color: "theme.palette.black.main", textAlign: "left" }}
            >
              i am...
            </Typography>
            <Box>
              <Stack
                direction={{
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "row",
                  xl: "row",
                }}
                p={2}
                sx={{
                  typography: {
                    textAlign: "center",
                  },
                  m: 0.5,
                  justifyContent: "space-evenly",
                }}
              >
                <Box sx={{ m: 1, p: 1 }}>
                  <List sx={{ m: 0, p: 0 }}>
                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText
                        primary="Software Engineer"
                        primaryTypographyProps={{
                          variant: "h4",
                          fontWeight: "bolder",
                          textAlign: "center",
                          m: 1,
                        }}
                      />
                    </ListItem>
                    <Typography
                      bgcolor={theme.palette.primary.light}
                      variant="h3"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TerminalTwoToneIcon
                        fontSize="inherit"
                        sx={{ color: theme.palette.black.main }}
                      />
                    </Typography>
                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography variant="h5" sx={{ textAlign: "center" }}>
                          Rutgers University
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography variant="h5" sx={{ textAlign: "center" }}>
                          Bachelor's of Computer Science
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bolder", textAlign: "center" }}
                        >
                          2018 - 2020
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
                <Box sx={{ m: 1, p: 1 }}>
                  <List sx={{ m: 0, p: 0 }}>
                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText
                        primary="Doctor of Pharmacy"
                        primaryTypographyProps={{
                          variant: "h4",
                          fontWeight: "bolder",
                          textAlign: "center",
                          m: 1,
                        }}
                      />
                    </ListItem>
                    <Typography
                      variant="h3"
                      bgcolor={theme.palette.primary.light}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LocalPharmacyIcon
                        fontSize="inherit"
                        sx={{ color: theme.palette.black.main }}
                      />
                    </Typography>
                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography variant="h5" sx={{ textAlign: "center" }}>
                          Rutgers University
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography variant="h5" sx={{ textAlign: "center" }}>
                          Ernest Mario School of Pharmacy
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bolder", textAlign: "center" }}
                        >
                          2009 - 2015
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
              </Stack>
            </Box>
          </Paper>

          {/* MY MISSION PAPER */}
          <Paper
            elevation={5}
            sx={{
              mx: 1,
              my: 2.5,
              p: 0.5,
              bgcolor: theme.palette.bg.main,
            }}
          >
            <Box m={1} p={0}>
              <Typography variant="h4" pb={2} sx={{ textAlign: "center" }}>
                after{" "}
                <Typography
                  display="inline"
                  variant="h4"
                  sx={{ color: "primary.main" }}
                >
                  10 years{" "}
                </Typography>
                as a{" "}
                <Typography
                  display="inline"
                  variant="h4"
                  sx={{ color: "primary.main" }}
                >
                  community pharmacist
                </Typography>
                ,
              </Typography>
              <Typography variant="h4" p={2} sx={{ textAlign: "center" }}>
                i am seeking a role as a
              </Typography>
              <Typography variant="h4" p={2} sx={{ textAlign: "center" }}>
                <Typography
                  display="inline"
                  variant="h4"
                  sx={{ color: "secondary.main" }}
                >
                  software developer
                </Typography>
                .
              </Typography>

              <Typography variant="h4" p={2} sx={{ textAlign: "center" }}>
                i am highly motivated to leverage and further develop my
                technical expertise to create something worthwhile.
              </Typography>
            </Box>
          </Paper>

          {/* CAREER SO FAR... */}
          <Paper
            elevation={5}
            sx={{
              mx: 1,
              marginTop: 3,
              marginBottom: 1,
              p: 0.5,
              bgcolor: theme.palette.bg.main,
            }}
          >
            <Typography
              variant="h4"
              m={1}
              sx={{
                textAlign: "left",
                color: "theme.palette.primary.contrast",
              }}
            >
              my career so far...
            </Typography>
            <br />
            <List
              dense
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* graduation card */}
              <ListItem sx={{ justifyContent: "center" }}>
                <Card
                  elevation={3}
                  sx={{
                    p: 2,
                    m: 1,
                    display: "inline",
                    typography: { textAlign: "center" },
                    bgcolor: theme.palette.bg.main,
                  }}
                >
                  <Typography variant="h4">
                    <SchoolIcon
                      sx={{
                        color: theme.palette.primary.dark,
                        fontSize: "inherit",
                      }}
                    />
                  </Typography>

                  <Typography variant="h5">Doctor of Pharmacy</Typography>
                  <Typography variant="h6">Rutgers University</Typography>
                  <Typography variant="h6">May 2015</Typography>
                </Card>
              </ListItem>

              {/* arrow down */}
              <ListItem sx={{ justifyContent: "center" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  <SouthIcon sx={{ m: 0.25, p: 0 }} />
                </Typography>
              </ListItem>

              {/* STAFF PHARMACIST WALGREENS CARD  */}
              <ListItem sx={{ justifyContent: "center" }}>
                <Card
                  elevation={5}
                  sx={{
                    p: 3,
                    m: 1,
                    display: "inline",
                    whiteSpace: "none",
                    typography: { textAlign: "center" },
                    bgcolor: theme.palette.bg.main,
                  }}
                >
                  <List sx={{ m: 0, px: 2, py: 2 }}>
                    <img
                      alt="walgreens logo"
                      src="../walgreens-logo-2024.png"
                      style={{
                        maxWidth: "362px",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    />

                    <ListItem sx={{ m: 0, p: 1 }}>
                      <ListItemText>
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "bolder",
                            textAlign: "center",
                          }}
                        >
                          Staff Pharmacist
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography variant="h6" sx={{ textAlign: "center" }}>
                          at Walgreens Pharmacy
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography variant="h6" sx={{ textAlign: "center" }}>
                          Millsboro, DE
                        </Typography>
                      </ListItemText>
                    </ListItem>

                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: "center" }}
                        >
                          Jul 2015 - Aug 2018
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Card>
              </ListItem>

              {/* arrow down */}
              <ListItem sx={{ justifyContent: "center" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  <SouthIcon sx={{ m: 0.25, p: 0 }} />
                </Typography>
              </ListItem>

              {/* DOUBLE STACK CARD */}
              <ListItem
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack
                  spacing={5}
                  direction={{ xs: "column", sm: "row", md: "row" }}
                  p={0}
                  sx={{
                    typography: {
                      textAlign: "center",
                    },
                    justifyContent: "center",
                  }}
                >
                  {/* shoprite card */}
                  <Card
                    elevation={5}
                    sx={{
                      p: 2,
                      m: 1,
                      display: "inline",
                      typography: { textAlign: "center" },
                      bgcolor: theme.palette.bg.main,
                    }}
                  >
                    <List sx={{ m: 0, px: 2, py: 2 }}>
                      <img
                        alt="shoprite rx logo"
                        src="../ShopRite_logo_PNG_(3).png"
                        style={{ width: "100%", maxWidth: "135px" }}
                      />

                      <ListItem sx={{ m: 0, p: 0 }}>
                        <ListItemText>
                          <Typography
                            variant="h5"
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            Pharmacist
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <ListItem sx={{ m: 0, p: 0 }}>
                        <ListItemText>
                          <Typography variant="h6" sx={{ textAlign: "center" }}>
                            ShopRite Pharmacy
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <ListItem sx={{ m: 0, p: 0 }}>
                        <ListItemText>
                          <Typography variant="h6" sx={{ textAlign: "center" }}>
                            Mount Laurel, NJ
                          </Typography>
                        </ListItemText>
                      </ListItem>

                      <ListItem sx={{ m: 0, p: 0 }}>
                        <ListItemText>
                          <Typography
                            variant="subtitle2"
                            sx={{ textAlign: "center" }}
                          >
                            Aug 2018 to May 2021
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Card>

                  {/* student card */}
                  <Card
                    elevation={5}
                    sx={{
                      p: 2,
                      m: 1,
                      display: "inline",
                      typography: { textAlign: "center" },
                      bgcolor: theme.palette.bg.main,
                    }}
                  >
                    <List sx={{ m: 0, px: 2, py: 2 }}>
                      <img
                        alt="rutgers logo"
                        src="../r-logo.png"
                        style={{ width: "100%", maxWidth: "135px" }}
                      />

                      <ListItem sx={{ m: 0, p: 0 }}>
                        <ListItemText
                          primary="Student"
                          primaryTypographyProps={{
                            variant: "h5",
                            textAlign: "center",
                          }}
                        />
                      </ListItem>

                      <ListItem sx={{ m: 0, p: 0 }}>
                        <ListItemText
                          primary="Computer Science"
                          primaryTypographyProps={{
                            variant: "h6",
                            textAlign: "center",
                          }}
                        />
                      </ListItem>

                      <ListItem sx={{ m: 0, p: 0 }}>
                        <ListItemText
                          primary="Rutgers University"
                          primaryTypographyProps={{
                            variant: "h6",
                            textAlign: "center",
                          }}
                        />
                      </ListItem>

                      <ListItem sx={{ m: 0, p: 0 }}>
                        <ListItemText>
                          <Typography
                            variant="subtitle2"
                            sx={{ textAlign: "center" }}
                          >
                            Aug 2018 to Dec 2020
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Card>
                </Stack>
              </ListItem>

              {/* down arrow */}
              <ListItem sx={{ justifyContent: "center" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  <SouthIcon sx={{ m: 0.25, p: 0 }} />
                </Typography>
              </ListItem>

              {/* comp sci graduation */}
              <ListItem sx={{ justifyContent: "center" }}>
                <Card
                  elevation={5}
                  sx={{
                    p: 5,
                    m: 1,
                    display: "inline",
                    whiteSpace: "none",
                    typography: { textAlign: "center" },
                    bgcolor: theme.palette.bg.main,
                  }}
                >
                  <Typography variant="h4">
                    <SchoolIcon
                      sx={{
                        color: theme.palette.primary.dark,
                        fontSize: "inherit",
                      }}
                    />
                  </Typography>
                  <Typography variant="h6">Bachelor's Degree</Typography>
                  <Typography variant="h5">Computer Science</Typography>
                  <Typography variant="h6">December 2020</Typography>
                </Card>
              </ListItem>

              {/* down arrow */}
              <ListItem sx={{ justifyContent: "center" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  <SouthIcon sx={{ m: 0.25, p: 0 }} />
                </Typography>
              </ListItem>

              {/* BAE systems */}
              <ListItem sx={{ justifyContent: "center" }}>
                <Card
                  elevation={5}
                  sx={{
                    p: 3,
                    m: 1,
                    display: "inline",
                    whiteSpace: "none",
                    typography: { textAlign: "center" },
                    bgcolor: theme.palette.bg.main,
                  }}
                >
                  <List sx={{ m: 0, px: 2, py: 2 }}>
                    <img
                      alt="baelogo"
                      src="../baelogo.png"
                      style={{
                        maxWidth: "362px",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    />

                    <ListItem sx={{ m: 0, p: 1 }}>
                      <ListItemText>
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "bolder",
                            textAlign: "center",
                          }}
                        >
                          Software Engineer I
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography variant="h6" sx={{ textAlign: "center" }}>
                          at BAE Systems
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography variant="h6" sx={{ textAlign: "center" }}>
                          Totowa, NJ
                        </Typography>
                      </ListItemText>
                    </ListItem>

                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: "center" }}
                        >
                          May 2021 - Dec 2021
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Card>
              </ListItem>

              {/* down arrow */}
              <ListItem sx={{ justifyContent: "center" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  <SouthIcon sx={{ m: 0.25, p: 0 }} />
                </Typography>
              </ListItem>

              {/* HILLSBOROUGH WALGREENS */}
              <ListItem sx={{ justifyContent: "center" }}>
                <Card
                  elevation={5}
                  sx={{
                    p: 3,
                    m: 1,
                    display: "inline",
                    whiteSpace: "none",
                    typography: { textAlign: "center" },
                    bgcolor: theme.palette.bg.main,
                  }}
                >
                  <List sx={{ m: 0, px: 2, py: 2 }}>
                    <img
                      alt="walgreens logo"
                      src="../walgreens-logo-2024.png"
                      style={{
                        maxWidth: "362px",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    />

                    <ListItem sx={{ m: 0, p: 1 }}>
                      <ListItemText>
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "bolder",
                            textAlign: "center",
                          }}
                        >
                          Staff Pharmacist
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography variant="h6" sx={{ textAlign: "center" }}>
                          at Walgreens Pharmacy
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography variant="h6" sx={{ textAlign: "center" }}>
                          Hillsborough, NJ
                        </Typography>
                      </ListItemText>
                    </ListItem>

                    <ListItem sx={{ m: 0, p: 0 }}>
                      <ListItemText>
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: "center" }}
                        >
                          Dec 2021 -{" "}
                          <Typography
                            display="inline"
                            variant="subtitle2"
                            fontWeight="bolder"
                          >
                            Present
                          </Typography>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Card>
              </ListItem>

              {/* down arrow */}
              <ListItem sx={{ justifyContent: "center" }}>
                <Typography variant="h3" sx={{ textAlign: "center" }}>
                  <SouthIcon sx={{ m: 0.25, p: 0 }} />
                </Typography>
              </ListItem>

              <ListItem sx={{ justifyContent: "center" }}>
                <Card
                  elevation={5}
                  sx={{
                    p: 3,
                    m: 1,
                    display: "inline",
                    whiteSpace: "none",
                    typography: { textAlign: "center" },
                    bgcolor: theme.palette.bg.main,
                  }}
                >
                  <Typography variant="h1">
                    <NotListedLocationIcon
                      sx={{
                        fontSize: "inherit",
                        color: theme.palette.primary.dark,
                      }}
                    />
                  </Typography>
                </Card>
              </ListItem>
              <ListItem sx={{ justifyContent: "center" }}>
                {/* Return button */}
                <Card sx={{ maxWidth: "md" }}>
                  <Link
                    color="secondary.main"
                    underline="hover"
                    href="#"
                    variant="body"
                    component={Button}
                    textTransform="none"
                    sx={{
                      textAlign: "center",
                      justifyContent: "center",
                      bgcolor: theme.palette.bg.main,
                    }}
                  >
                    back to top
                  </Link>
                </Card>
              </ListItem>
            </List>
          </Paper>
        </Stack>
      </Container>
    </ThemeProvider>
  );
};

export default Bio;
