import {
  List,
  ListItem,
  ListItemText,
  Stack,
  ThemeProvider,
  Typography,
  useTheme,
  Link,
  ListItemIcon,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import CodeTwoToneIcon from "@mui/icons-material/CodeTwoTone";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import PsychologyIcon from "@mui/icons-material/Psychology";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import StyledGitHubIcon from "../styled/StyledGitHubIcon.jsx";
import ConstructionIcon from "@mui/icons-material/Construction";
import Tweet from "../styled/Tweet.jsx";
import SentimentSatisfiedAltTwoToneIcon from "@mui/icons-material/SentimentSatisfiedAltTwoTone";
import ColorLensTwoToneIcon from "@mui/icons-material/ColorLensTwoTone";

// let theme = createTheme({
//   palette: {
//     primary: {
//       main: "#27996B",
//     },
//     secondary: {
//       main: "#FF3634",
//     },
//     bg: {
//       main: "#f5f5f5",
//     },
//   },
//   textTransform: "none",
//   typography: {
//     fontFamily: "OCFormatCollage",
//   },
// });
// theme = responsiveFontSizes(theme);

const Projects = () => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="lg"
        disableGutters
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        <Paper
          elevation={5}
          sx={{
            my: 3,
            mx: 1,
            p: 1,
            bgcolor: "bg.main",
          }}
        >
          <Typography variant="h2" m={1} sx={{ textAlign: "left" }}>
            some things i have created...
          </Typography>

          <Link
            color="primary.dark"
            underline="always"
            href="https://www.github.com/kupcha"
            target="_blank"
            variant="h5"
            component={Button}
            textTransform="none"
            textAlign="left"
            display="flex"
          >
            <StyledGitHubIcon /> all projects viewable on my GitHub
          </Link>
          <Stack spacing={2} sx={{ alignItems: "center" }}>
            {/* patkupcha.xyz, my personal portfolio website  */}
            <Card
              sx={{
                maxWidth: "md",
                width: "100%",
                bgcolor: theme.palette.bg.main,
              }}
            >
              <List sx={{ justifyContent: "center", textAlign: "center" }}>
                <ListItem>
                  <ListItemIcon>
                    <LocationOnIcon
                      sx={{ color: "secondary.main" }}
                      fontSize="large"
                    />{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Link
                        underline="hover"
                        href="https://www.patkupcha.xyz"
                        target="_blank"
                        variant="h3"
                      >
                        patkupcha.xyz
                      </Link>
                    }
                    secondary="you are here"
                    secondaryTypographyProps={{ color: "secondary.main" }}
                  />
                </ListItem>
                <ListItem>
                  <Link
                    color="primary.light"
                    underline="hover"
                    href="https://www.github.com/kupcha/portfolio"
                    target="_blank"
                    variant="h5"
                  >
                    view repository on GitHub
                  </Link>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CodeTwoToneIcon
                      sx={{ color: "primary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Single-page web application using React & MaterialUI components, deployed on AWS Amplify"></ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ColorLensTwoToneIcon
                      sx={{ color: "secondary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText>
                    Multiple color palettes,{" "}
                    {
                      <Typography
                        display="inline"
                        sx={{ color: theme.palette.secondary.main }}
                      >
                        try refreshing the page
                      </Typography>
                    }
                    !
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <SentimentSatisfiedAltTwoToneIcon
                      sx={{ color: "secondary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText primary="I hope you will find these samples of my work interesting" />
                </ListItem>
              </List>
            </Card>

            <Card
              sx={{
                maxWidth: "md",
                width: "100%",
                bgcolor: theme.palette.bg.main,
              }}
            >
              <List>
                <ListItem>
                  <ListItemText
                    primary={
                      <Link
                        underline="hover"
                        href="https://github.com/kupcha/Parallel-Tweet-Bot"
                        target="_blank"
                        variant="h3"
                      >
                        ParallelTCG Twitter Bot & Web Scraper
                      </Link>
                    }
                    secondary={
                      <Link
                        color="secondary.main"
                        underline="hover"
                        href="https://www.x.com/parallelcardbot"
                        target="_blank"
                        variant="body1"
                      >
                        follow on twitter @parallelcardbot
                      </Link>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <LiveHelpIcon
                      sx={{ color: "secondary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Parallel is a trading card game. I scraped the information
                      and stats of every card from their website and created a
                      Twitter bot that tweets out the card art images along with
                      their description and in-game abilities."
                    secondary={
                      <Link
                        underline="hover"
                        href="https://parallel.life"
                        target="_blank"
                      >
                        you can learn more about Parallel here
                      </Link>
                    }
                  ></ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CodeTwoToneIcon
                      sx={{ color: "primary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Python script used to scrape ParallelTCG website and write all card data to a .csv file"
                    secondary="I also chose to download all card art because I am a fan, but this can be switched off"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CodeTwoToneIcon
                      sx={{ color: "primary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Python & Tweepy library uses Twitter API to upload card art and tweet at regular intervals" />
                </ListItem>
                <ListItem sx={{ justifyContent: "center" }}>
                  <Tweet />
                </ListItem>
              </List>
            </Card>

            {/* Ethereum Wallet Tracker project */}
            <Card
              sx={{
                maxWidth: "md",
                width: "100%",
                bgcolor: theme.palette.bg.main,
              }}
            >
              <List>
                <ListItem>
                  <ListItemText
                    primary={
                      <Link
                        underline="hover"
                        href="https://github.com/kupcha/wallet-tracker"
                        target="_blank"
                        variant="h3"
                      >
                        Ethereum Address Activity Tracker
                      </Link>
                    }
                    secondary="easily follow smart money buys and sells with this wallet tracker"
                    secondaryTypographyProps={{ color: "secondary.main" }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText secondary="Intended for desktop viewing">
                    <Link
                      component={RouterLink}
                      underline="hover"
                      variant="h5"
                      to="/projects/wallet-activity"
                      sx={{ color: "primary.light" }}
                    >
                      Check wallet activity here
                    </Link>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <Link
                    underline="hover"
                    href="https://github.com/kupcha/wallet-tracker/blob/main/handleTransaction.js"
                    variant="h5"
                    sx={{ color: "primary.light" }}
                  >
                    View Lambda function code here
                  </Link>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CodeTwoToneIcon
                      sx={{ color: "primary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="AlchemyAPI"
                    secondary="on-chain activity triggers Alchemy API to send transaction data to AWS Lambda function"
                  >
                    <Typography color="secondary.main">AlchemyAPI</Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CodeTwoToneIcon
                      sx={{ color: "primary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="AWS Lambda Function"
                    secondary="process transaction data and writes to AWS DynamoDB table"
                  ></ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ThumbUpOffAltIcon
                      sx={{ color: "secondary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Interacted with Ethereum blockchain via webhook" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ThumbUpOffAltIcon
                      sx={{ color: "secondary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Integrated AWS ecosystem cohesively using Lambda, API Gateway, DynamoDB, and CloudWatch" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PsychologyIcon
                      sx={{ color: "secondary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Although the 'Wallet Activity' API from Alchemy is suitable to listen for when a transaction occurs, it proved to be lacking in the ability to identify specifics of the transaction. It proved to be quite difficult to deduce whether a buy, transfer in, or minting transaction was occurring."
                    secondary="Existing tools like Etherscan & OpenSea proved to be more robust in this regard."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PsychologyIcon
                      sx={{ color: "secondary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="If multiple tokens (eg. ETH & ERC721) were being transferred, two separate webhook responses are triggered which complicated design of the event handling."
                    secondary="For this reason, event handling was limited to ERC721 & ERC1155 token transactions only and excluded ERC20 tokens."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PsychologyIcon
                      sx={{ color: "secondary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Future iterations could utilize ethers.js or web3.js libraries to trigger a user's own transaction in response to the listener events."
                    secondary="While this would be possible, it would not be the ideal trading setup as too much information is lacking from the current webhook response."
                  />
                </ListItem>
              </List>
            </Card>

            {/* Travel Review Web Application */}
            <Card
              sx={{
                maxWidth: "md",
                width: "100%",
                bgcolor: theme.palette.bg.main,
              }}
            >
              <List>
                <ListItem>
                  <ListItemText
                    primary="Travel Review Web Application"
                    primaryTypographyProps={{
                      color: "primary.main",
                      variant: "h3",
                    }}
                    secondary="exchange recommendations to venues at all your travel destionations"
                  />
                </ListItem>
                <ListItem>
                  <Link
                    color="primary.light"
                    underline="hover"
                    href="https://www.github.com/kupcha/nomads-land"
                    target="_blank"
                    variant="h5"
                  >
                    view repository on GitHub
                  </Link>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ThumbUpOffAltIcon
                      sx={{ color: "secondary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Full stack web application that includes user authentication" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CodeTwoToneIcon
                      sx={{ color: "primary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Express, EJS, MongoDB, Mongoose, Auth0, Google Maps API"></ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CodeTwoToneIcon
                      sx={{ color: "primary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Submit survey forms to review travel destinations, build a Google Map with recommended venues"></ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CodeTwoToneIcon
                      sx={{ color: "primary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Login saves user specific submissions"></ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <CodeTwoToneIcon
                      sx={{ color: "primary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Previously hosted on AWS Elastic Beanstalk"
                    secondary="Due to limits to AWS Free Tier I chose to turn off the server but I am in the process of resurecting the live application."
                  ></ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PsychologyIcon
                      sx={{ color: "secondary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText primary="For my first independent full stack project, I was overall pleased with the end product. However, moving forward as a developer I realize I must be more conscious of some key principles. This became offensively obvious to me after reading 'Clean Code'." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ConstructionIcon
                      sx={{ color: "secondary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="In the future I would like to show improvement on code readability, documentation, maintainability, and modularity. By better adhering to industry standards I will be better equipped to meaningfully contribute within a large scale collaborative environment."
                    secondary="I would hope my application of these principles is apparent in my more recent work."
                  />
                </ListItem>
              </List>
            </Card>

            {/* Company Scheduler project */}
            <Card
              sx={{
                maxWidth: "md",
                width: "100%",
                bgcolor: theme.palette.bg.main,
              }}
            >
              <List>
                <ListItem>
                  <ListItemText
                    primary="Company Scheduler"
                    primaryTypographyProps={{
                      color: "primary.main",
                      variant: "h3",
                    }}
                    secondary="automate the process of creating a schedule for employees based on their availability and time preferences"
                  />
                </ListItem>
                <ListItem>
                  <Link
                    color="primary.light"
                    underline="hover"
                    href="https://github.com/kupcha/CompanyScheduler"
                    target="_blank"
                    variant="h5"
                  >
                    view repository on GitHub
                  </Link>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CodeTwoToneIcon
                      sx={{ color: "primary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Collaborative group project completed for university assignment" />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <CodeTwoToneIcon
                      sx={{ color: "primary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Java, JavaFX, MySQL, AWS RDS" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ThumbUpOffAltIcon
                      sx={{ color: "secondary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Effectively collaborated in a remote environment with version control through Git" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ConstructionIcon
                      sx={{ color: "secondary.main" }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Bugs with edge cases highlight importance of rigorous unit testing" />
                </ListItem>
              </List>
            </Card>

            {/* Return button */}
            <Card sx={{ maxWidth: "md" }}>
              <Link
                color="secondary.main"
                underline="hover"
                href="#"
                variant="body"
                component={Button}
                textTransform="none"
                sx={{
                  textAlign: "center",
                  justifyContent: "center",
                  bgcolor: theme.palette.bg.main,
                }}
              >
                back to top
              </Link>
            </Card>
          </Stack>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};
export default Projects;
