import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./css/index.css";
import App from "./routes/App.jsx";
import Bio from "./routes/Bio.jsx";
import Projects from "./routes/Projects.jsx";
import Contact from "./routes/Contact.jsx";
import ErrorPage from "./error.jsx";
import "prismjs";
import "prismjs/themes/prism.css"; // Choose the theme you want, or create your own
import "prismjs/components/prism-javascript"; // Import the language you need
import ActivityTable from "./routes/Projects/ActivityTable.jsx";
import "./styled/fonts/fonts.css";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles"; // Import from Material-UI

const red = "#FF3634";
const lightred = "#ffccd3";
const green = "#27996B";
const lightgreen = "#c1e4d4";
const blue = "#3C68FF";
const lightblue = "#c5cbff";
const yellow = "#FFD337";

let greenred = createTheme({
  palette: {
    primary: {
      main: green,
      lighter: lightgreen,
    },
    secondary: {
      main: red,
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#000000",
    },
    bg: {
      main: "#f5f5f5",
    },
  },
  textTransform: "none",
  typography: {
    fontFamily: "OCFormatCollage",
  },
});
greenred = responsiveFontSizes(greenred);

let bluegreen = createTheme({
  palette: {
    primary: {
      main: blue,
      lighter: lightblue,
    },
    secondary: {
      main: green,
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#000000",
    },
    bg: {
      main: "#f5f5f5",
    },
  },
  textTransform: "none",
  typography: {
    fontFamily: "OCFormatCollage",
  },
});
bluegreen = responsiveFontSizes(bluegreen);

let redgreen = createTheme({
  palette: {
    primary: {
      main: red,
      lighter: lightred,
    },
    secondary: {
      main: green,
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#000000",
    },
    bg: {
      main: "#f5f5f5",
    },
  },
  textTransform: "none",
  typography: {
    fontFamily: "OCFormatCollage",
  },
});
redgreen = responsiveFontSizes(redgreen);

let bluered = createTheme({
  palette: {
    primary: {
      main: blue,
      lighter: lightblue,
    },
    secondary: {
      main: red,
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#000000",
    },
    bg: {
      main: "#f5f5f5",
    },
  },
  textTransform: "none",
  typography: {
    fontFamily: "OCFormatCollage",
  },
});
bluered = responsiveFontSizes(bluered);

let blueyellow = createTheme({
  palette: {
    primary: {
      main: blue,
      lighter: lightblue,
    },
    secondary: {
      main: yellow,
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#000000",
    },
    bg: {
      main: "white.main",
    },
  },
  textTransform: "none",
  typography: {
    fontFamily: "OCFormatCollage",
  },
});
blueyellow = responsiveFontSizes(blueyellow);

let redyellow = createTheme({
  palette: {
    primary: {
      main: red,
      lighter: lightred,
    },
    secondary: {
      main: yellow,
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#434343",
    },
    bg: {
      main: "white.main",
    },
  },
  textTransform: "none",
  typography: {
    fontFamily: "OCFormatCollage",
  },
});
redyellow = responsiveFontSizes(redyellow);

const coinflip = Math.random();

var randomTheme = greenred;
if (coinflip <= 0.2) {
  randomTheme = greenred;
} else if (coinflip > 0.2 && coinflip <= 0.4) {
  randomTheme = bluegreen;
} else if (coinflip > 0.4 && coinflip <= 0.6) {
  randomTheme = redgreen;
} else if (coinflip > 0.6 && coinflip <= 0.8) {
  randomTheme = bluered;
} else if (coinflip > 0.8 && coinflip <= 0.9) {
  randomTheme = redyellow;
} else {
  randomTheme = blueyellow;
}
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Bio />,
      },
      {
        path: "bio",
        element: <Bio />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "projects/wallet-activity",
        element: <ActivityTable />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ThemeProvider theme={randomTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);
