import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  ThemeProvider,
  Container,
  Typography,
} from "@mui/material";
import {
  DynamoDBClient,
  ScanCommand,
  QueryCommand,
} from "@aws-sdk/client-dynamodb";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { format, parseISO } from "date-fns";

// let theme = createTheme({
//   palette: {
//     primary: {
//       main: "#27996B",
//     },
//     secondary: {
//       main: "#FF3634",
//     },
//   },
//   bgcolor: "#f5f5f5",
//   textTransform: "none",
//   typography: {
//     fontFamily: ["Ubuntu", "sans-serif"].join(","),
//     color: "primary.lighter",
//   },
// });
// theme = responsiveFontSizes(theme);

const client = new DynamoDBClient({
  region: "us-east-2",
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

const fetchTableItems = async () => {
  const input = {
    TableName: "Wallet-Tracker",
    IndexName: "all-createdAt-index",
    KeyConditionExpression: "#all = :allValue",
    ExpressionAttributeNames: {
      "#all": "all",
    },
    ExpressionAttributeValues: {
      ":allValue": { S: "1" },
    },
    ProjectionExpression:
      "transactionHash, createdAt, contractAddress, amount, buySell, ETHValue",
    ScanIndexForward: false,
  };
  const command = new QueryCommand(input);
  try {
    const response = await client.send(command);
    return response.Items || [];
  } catch (error) {
    console.error("Error fetching data from DynamoDB:", error);
    return [];
  }
};

function truncateString(str, startLength = 7, endLength = 7) {
  if (str.length <= startLength + endLength) {
    return str;
  }
  return `${str.substring(0, startLength)}...${str.substring(
    str.length - endLength
  )}`;
}

function formatDate(dateStr) {
  return format(parseISO(dateStr), "PPPppp");
}

const ActivityTable = () => {
  const theme = useTheme();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchTableItems();
      setItems(response);
    };

    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          display: "block",
          justifyContent: "center",
          alignItems: "center",
          m: 1,
        }}
      >
        <Paper sx={{ m: 1, display: "block", textAlign: "center" }}>
          <Typography
            variant="body1"
            fontWeight="bolder"
            color={theme.palette.primary.dark}
            sx={{ m: 1 }}
          >
            In this example, we are tracking the transactions made by{" "}
            {
              <Link
                sx={{ color: theme.palette.primary.dark }}
                target="_blank"
                href="https://x.com/HollanderAdam"
              >
                Adam Hollander
              </Link>
            }
            , a popular project founder and "influencer" in the Ethereum and NFT
            space.
          </Typography>
          <Typography
            variant="body1"
            fontWeight="bolder"
            color={theme.palette.primary.dark}
            sx={{ m: 1 }}
          >
            The specific Ethereum address we are tracking is:{" "}
            {
              <Link
                target="_blank"
                href="https://etherscan.io/address/0x8ACFe17a5Fe62966A27E579e28c10e936de614f2"
                sx={{ color: theme.palette.secondary.light }}
              >
                0x8ACFe17a5Fe62966A27E579e28c10e936de614f2
              </Link>
            }
          </Typography>
        </Paper>
        <TableContainer
          component={Paper}
          sx={{ m: 1, display: "block", textAlign: "center" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="body2"
                    fontWeight="bolder"
                    color={theme.palette.secondary.dark}
                    textAlign="center"
                  >
                    Transaction Hash
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    fontWeight="bolder"
                    color={theme.palette.secondary.dark}
                    textAlign="center"
                  >
                    Created At
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    fontWeight="bolder"
                    color={theme.palette.secondary.dark}
                    textAlign="center"
                  >
                    Contract Address
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    fontWeight="bolder"
                    color={theme.palette.secondary.dark}
                    textAlign="center"
                  >
                    Tx Type
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    fontWeight="bolder"
                    color={theme.palette.secondary.dark}
                    textAlign="center"
                  >
                    Amount
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    fontWeight="bolder"
                    color={theme.palette.secondary.dark}
                    textAlign="center"
                  >
                    Value in ETH
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    component={Link}
                    href={`https://etherscan.io/tx/${item.transactionHash.S}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ textAlign: "center" }}
                  >
                    {truncateString(item.transactionHash.S)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item.createdAt ? formatDate(item.createdAt.S) : "N/A"}
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center" }}
                    component={Link}
                    href={`https://magiceden.io/collections/ethereum/${item.contractAddress.S}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {truncateString(item.contractAddress.S)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item.buySell ? item.buySell.S : "N/A"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item.amount ? item.amount.N : "N/A"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item.ETHValue ? item.ETHValue.N : "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </ThemeProvider>
  );
};

export default ActivityTable;
