import React from "react";
import { Outlet, Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import "@fontsource/ubuntu/500.css";
import "../css/App.css";
import Bio from "./Bio";
import "../styled/fonts/fonts.css";

// let theme = createTheme({
//   palette: {
//     primary: {
//       main: "#27996B",
//       lighter: "#c1e4d4",
//     },
//     secondary: {
//       main: "#FF3634",
//     },
//     white: {
//       main: "#ffffff",
//     },
//     black: {
//       main: "#000000",
//     },
//   },
//   textTransform: "none",
//   typography: {
//     fontFamily: "OCFormatCollage",
//   },
// });
// theme = responsiveFontSizes(theme);

function App() {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <Container
        disableGutters
        maxWidth="100%"
        m={0}
        p={0}
        sx={{ bgcolor: "primary.lighter" }}
      >
        <AppBar position="static">
          <Toolbar>
            <Typography theme={theme} component="div" sx={{ flexGrow: 1 }}>
              <Button
                component={Link}
                to="/"
                size="large"
                variant="contained"
                color="primary"
                sx={{
                  m: 1,
                  textTransform: "none",
                  ":hover": { color: "black.main", bgcolor: "secondary.main" },
                }}
              >
                <Typography variant="h3">pat kupcha</Typography>
              </Button>
            </Typography>

            <Button
              variant="contained"
              component={Link}
              size="large"
              to="/projects"
              sx={{ m: 1, textTransform: "none" }}
            >
              projects
            </Button>

            <Button
              variant="contained"
              component={Link}
              to="/contact"
              size="large"
              sx={{
                m: 1,
                textTransform: "none",
                ":hover": { color: "white.main", bgcolor: "primary.light" },
              }}
            >
              contact
            </Button>
          </Toolbar>
        </AppBar>
        <Outlet>
          <Bio />
        </Outlet>
      </Container>
    </ThemeProvider>
  );
}

export default App;
