import React, { useEffect } from "react";
import { Box } from "@mui/material";

function TwitterEmbed() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    script.charset = "utf-8";

    // Append script to the body or specific element
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  const tweetHtml = `
<blockquote class="twitter-tweet" data-dnt="true"><p lang="en" dir="ltr">The Void Unleashed,<br>Shroud Effect,<br><br>An eternity of waiting, unleashed in a single moment. <a href="https://t.co/CPPbSUyE69">pic.twitter.com/CPPbSUyE69</a></p>&mdash; Parallel Card Bot (@parallelcardbot) <a href="https://twitter.com/parallelcardbot/status/1797839073010184521?ref_src=twsrc%5Etfw">June 4, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> `;

  return <Box dangerouslySetInnerHTML={{ __html: tweetHtml }} />;
}

export default TwitterEmbed;
